/* eslint-disable no-use-before-define */
/* eslint-disable no-inner-declarations */
/* eslint-disable prefer-template */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */

/*
    This script is meant to run on unsupported browsers.
    We can therefore not use React, Babel transpilation
    or modern browser features.
*/

function showUpgradeModal() {
  if (document.getElementById('outdated')) {
    return;
  }

  var primaryColor = '#313032';
  var textColor = '#545454';
  var title = 'Your browser is outdated';
  var message =
    'The site may not work as expected. Would you like to upgrade before continuing?';
  var upgradeButtonText = 'Yes please!';
  var upgradeHref = 'http://outdatedbrowser.com';
  var declineText = "No thanks, I'll take my chances.";

  var overlay = document.createElement('div');

  overlay.setAttribute(
    'style',
    'position: fixed;' +
      'display: table;' +
      'cursor: pointer;' +
      'background: black;' +
      'background: rgba(0,0,0,.50);' +
      'top: 0px;' +
      'left: 0px;' +
      'width: 100%;' +
      'height: 100%;' +
      'z-index: 99999 !important;'
  );
  overlay.setAttribute('id', 'outdated');

  var modal = document.createElement('div');

  modal.setAttribute(
    'style',
    'padding: 16px;' +
      'font-family: "Montserrat Variable",sans-serif;' +
      'color: ' +
      textColor +
      ';' +
      'display: table-cell;' +
      'vertical-align: middle;'
  );

  var upgradeButtonStyle =
    'display: inline-block;' +
    'padding: 0.5rem 1rem;' +
    ' background-color: ' +
    primaryColor +
    ';' +
    'color: white;' +
    'border-radius: 4px;' +
    'font-weight: 600;' +
    'text-decoration: none;';

  var declineByttonStyle =
    'font-weight: 600;' +
    'color: ' +
    textColor +
    ';' +
    'display: inline-block;' +
    'padding: 0.5rem 1rem;' +
    'cursor: pointer;' +
    'text-decoration: none;';

  modal.innerHTML =
    ' <div style="background: white; padding: 16px; border-radius: 4px; max-width: 700px; margin-left:auto;margin-right:auto;">' +
    '<h2 style="font-weight: 600; line-height: 1.2em; font-size: 24px; margin-bottom: 1rem;">' +
    title +
    '</h2>' +
    '<p style="margin-bottom: 1rem;">' +
    message +
    '</p>' +
    '<a style="' +
    upgradeButtonStyle +
    '"' +
    'target="_blank" href="' +
    upgradeHref +
    '">' +
    upgradeButtonText +
    '</a>' +
    '<a style="' +
    declineByttonStyle +
    '" onclick="overlay = document.getElementById(\'outdated\'); overlay.parentNode.removeChild(overlay);">' +
    declineText +
    '</a>' +
    '</div>';

  overlay.appendChild(modal);
  document.body.appendChild(overlay);
  window['overlay'] = overlay;
}

if (typeof window !== 'undefined') {
  var minBrowserVersions = {
    chrome: '67',
    edge: '79',
    firefox: '68',
    ie: '12',
    opera: '64',
    safari: '14',
  };

  var browser = (function () {
    var ua = window.navigator.userAgent;
    var tem;
    var M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];

    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'ie', version: tem[1] || '' };
    } else if (/MSIE/i.test(M[1])) {
      return { name: 'ie', version: M[2] || '' };
    }

    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR\/(\d+)/);

      if (tem != null) {
        return { name: 'opera', version: tem[1] };
      }
    }

    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];

    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }

    return {
      name: M[0],
      version: M[1],
    };
  })();

  var minVersion = minBrowserVersions[browser['name']];
  var isUnsupportedBrowser = minVersion && browser['version'] < minVersion;
  var hasMissingBrowserAPIs =
    !window.URL || !window.Proxy || !window.fetch || !window.Map;
  var isOutdated = isUnsupportedBrowser || hasMissingBrowserAPIs;

  window['isOutdated'] = isOutdated;
  window['showUpgradeModal'] = showUpgradeModal;

  if (isOutdated) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    addLoadEvent(showUpgradeModal);

    function addLoadEvent(func) {
      var oldonload = window.onload;
      if (typeof window.onload !== 'function') {
        window.onload = func;
      } else {
        window.onload = function () {
          if (oldonload) {
            // @ts-ignore
            oldonload();
          }
          func();
        };
      }
    }
  }
}
